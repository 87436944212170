/* global console, document, Excel, Office */
import "../style.css";
import { jwtDecode } from "jwt-decode";
import { authorizeUser, updateModel, fetchSecUrlsForTickerFromApi } from "../helpers/middle-tier-calls";
import TomSelect from "tom-select"
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: "https://d8c765fe6428879d50ba2a8093731d39@o4506915463102464.ingest.us.sentry.io/4507012731109376",

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.tenkay\.ai\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const SentryLoggingLevels = {
  info: 'info',
  debug: 'debug',
  warning: 'warning',
  error: 'error',
  fatal: 'fatal'
}

class MultiSteps {
  constructor() {
    if (MultiSteps.instance) {
      return MultiSteps.instance;
    }
    this.steps = [];
    this.ticker = null;
    // This my be either a string or array of strings
    this.source_urls = [];
    this.sec_url_options = [];
    MultiSteps.instance = this;
  }

  getTicker() {
    return this.ticker;
  }

  getSourceUrls() {
    return this.source_urls;
  }

  getSecUrlOptions() {
    return this.sec_url_options;
  }

  setTicker(ticker) {
    this.ticker = ticker;
  }

  setSourceUrls(source_urls) {
    this.source_urls = source_urls;
  }

  setSecUrlOptions(sec_url_options) {
    this.sec_url_options = sec_url_options;
  }

  addStep(step) {
    // TODO may be better to use a dictionary and tab names as keys?
    this.steps.push(step);
  }

  removeStep(index) {
    if (index >= 0 && index < this.steps.length) {
      this.steps.splice(index, 1);
    } else {
      console.error('Index out of bounds');
    }
  }

  getSteps() {
    return this.steps;
  }

}

class MultiSelect {
  constructor() {
    if (MultiSelect.instance) {
      return MultiSelect.instance;
    }
    this.element = null
    MultiSelect.instance = this;
  }

  getElement() {
    return this.element
  }

  resizeSiblingButton(event) {
    const tsControl = document.querySelector(".ts-wrapper");
    const buttonWrapper = document.getElementById("sec-url-dropdown-button-wrapper")
    /**
     * Ideally we wouldn't have to add a timeout here, however, reacting to the event immediately
     * had unintended side effects. It seems that Tom Select continues to re-size after its events fire
     */
    window.setTimeout(() => {
      buttonWrapper.style.height = `${tsControl.offsetHeight}px`;
    }, 1)
  }
  
  initialize() {
    this.element = new TomSelect('#source_urls',{
      valueField: 'url',
      labelField: 'title',
      searchField: 'title',
      create: true,
      hidePlaceholder: true,
      onItemAdd: function() {
        this.close();
      },
      render: {
        item: function (data, escape) {
          // What appears in the input field after selection
          return `<div class="selected-item">${escape(data.url)}</div>`;
        },
      }
    });
    /**
     * Ideally we could avoid listening to all of these events, however our dropdown button
     * is having a difficult time auto-sizing to fill its parent, which is itself auto-sizing
     * to fit its children. As the Tom Select grows, it does not seem to affect the height explicitly
     * on the parent, and thus the button fails to match its parent's height.
     */
    this.element.on('change', (() => {this.resizeSiblingButton('change')}))
    this.element.on('dropdown_open', (() => {this.resizeSiblingButton('dropdown_open')}))
    this.element.on('dropdown_close', (() => {this.resizeSiblingButton('dropdown_close')}))
    this.element.on('item_select', ((item) => {
      copyToClipboard(item)
      this.resizeSiblingButton('item_select')
    }))
    this.element.on('focus', (() => {this.resizeSiblingButton('focus')}))
    this.element.on('blur', (() => {this.resizeSiblingButton('blur')}))
  }
}

function copyToClipboard(el) {
  const url = el.getAttribute('data-value')
  const tempInput = document.createElement("textarea");
  tempInput.value = url;
  document.body.appendChild(tempInput);
  tempInput.select();
  try {
    /**
     * navigator.clipboard.writeText seemingly blocked
     */
    document.execCommand("copy");
    showCopyNotification(el);
  } catch (err) {
    console.error("Copy failed", err);
  }
  document.body.removeChild(tempInput);
}

function showCopyNotification(el) {
  const bubble = document.createElement("div");
  bubble.className = "copy-bubble rounded-md bg-blue-500 text-sm text-white p-1";
  bubble.textContent = "Copied";
  document.body.appendChild(bubble);

  // Position it near the clicked element
  const rect = el.getBoundingClientRect();
  bubble.style.fontSize - '0.875rem'
  bubble.style.position = 'absolute'
  bubble.style.zIndex = 100
  bubble.style.left = `${rect.left + rect.width / 2}px`;
  bubble.style.top = `${rect.top - 40}px`; // Slightly above the element

  // Remove after 1s
  setTimeout(() => {
    bubble.classList.add("opacity-0", "transition-opacity", "duration-500");
    setTimeout(() => bubble.remove(), 500);
  }, 500);
}

Office.onReady(async (info) => {
  // Check that we loaded into Excel
  if (info.host === Office.HostType.Excel) {

    // Event delegation on our content container to avoid creating and tearing down event handlers every
    // time we swap our innerHTML
    document.getElementById("main").onclick = async (event) => {
      const result = await mainClickHandler(event);
      if (result === true) {
        event.stopPropagation();
      }
    };
    document.getElementById("main").onchange = async (event) => {
      const result = await mainChangeHandler(event);
      if (result === true) {
        event.stopPropagation();
      }
    }
    document.getElementById("main").addEventListener("focusout", async (event) => {
      const result = await mainFocusOutHandler(event);
      if (result === true) {
        event.stopPropagation();
      }
    });
  }
});

window.userAuthenticated = async () => {
  await loadForm(true);
};

window.sentrySetUser = () => {
  Sentry.setUser({ 
    id: Clerk.user.id,
    email: Clerk.user.primaryEmailAddress.emailAddress,
  });
};

async function loadForm(firstStep=false) {
  await loadView("views/form.html", "main");
  await tryCatch(setSheetsDropdown);
  // If this is the first step, we have just loaded the add-in. 
  // Display our Update button plus the option to add another step
  // If not, only display the button to add the step, taking the user back to the steps page
  if (firstStep) {
    document.getElementById("requestButton").classList.remove("hidden");
    document.getElementById("addAnotherStepButton").classList.remove("hidden");
  } else {
    document.getElementById("addUpdateStepButton").classList.remove("hidden");
  }
  const multi_select = new MultiSelect()
  multi_select.initialize()
  prePopulateForm(multi_select);
}

function prePopulateForm(multi_select) {
  let multiSteps = new MultiSteps();

  // Set the ticker if we have it
  const ticker = multiSteps.getTicker();
  ticker && (document.getElementById("ticker").value = ticker);

  // Set the sec url if we have it
  const source_urls = multiSteps.getSourceUrls();
  source_urls && (
    multi_select.getElement().setValue(source_urls)
  );

  // Add all URLs to our dropdown if we have them
  const sec_url_options = multiSteps.getSecUrlOptions();
  if (sec_url_options.length) {
    sec_url_options.forEach((sec_url, index) => {
      let release = createReleaseTitle(sec_url);
      multi_select.getElement().addOption({
        id: index,
        title: release,
        url: sec_url["url"]
      })
    });
    enableButton();
  }
}

async function mainClickHandler(event) {

  const step_id = event.target.dataset.stepid;

  if (step_id) {
    deleteUpdateStep(step_id);
    return true;
  }

  // For this case we need to catch clicks anywhere inside of the element, meaning child elements
  const targetElement = event.target.closest('#sec-url-dropdown-button');
  if (targetElement) {
    const multi_select = new MultiSelect()
    multi_select.getElement().open()
    return true;
  }

  // This is not ideal, but we can catch any click 'outside' of the SEC URL dropdown here and close the dropdown
  // This is because if we click on a SEC URL in the dropdown, we catch it above and continue to catch other clicks
  const dropdown = document.getElementById('sec-url-dropdown');
  if (dropdown) {
    dropdown.classList.add('hidden');
  }

  const element_id = event.target.id;

  switch(element_id) {
    case "requestButton":
      document.getElementById("addAnotherStepButton").classList.add("hidden");
      await tryCatch(prepareToSubmitUrl);
      return true;
    case "requestButtonMultiStep":
      await runAllUpdateSteps();
      return true;
    case "initializeAddStep":
      await loadForm(false);
      return true;
    case "addUpdateStepButton":
      await addUpdateStep();
      return true;
    case "addAnotherStepButton":
      await addUpdateStep();
      return true;
    default:
      return false;
  }
}

async function mainChangeHandler(event) {

  const element_id = event.target.id;

  switch(element_id) {
    case "target_year":
      changeYear(event);
      return true;
    case "target_quarter":
      changeQuarter(event);
      return true;
    default:
      return false;
  }
}

async function mainFocusOutHandler(event) {

  const element_id = event.target.id;

  switch(element_id) {
    case "ticker":
      // When a ticker has been entered, fetch SEC URLs
      fetchSecUrlsForTicker(event);
      return true;
    default:
      return false;
  }
}

async function loadView(view, content_element_id) {
  // Fetch our local fragment
  const response = await fetch(view);
  const html = await response.text();
  // Update our view
  document.getElementById(content_element_id).innerHTML = html;
}

async function tryCatch(callback) {
  try {
    await callback();
  } catch (error) {
    // TODO: ensure errors are logged at Sentry.io or similar
    console.error("Error caught: ", error);
    document.getElementById("requestButton").innerHTML = "Update Model";
    let element = resetMessageUI("apiResponse");
    displayError(element, "Something went wrong, please retry.");
  }
}

function getMultiSelectValues() {
  const multi_select = new MultiSelect
  return multi_select.getElement().getValue();
}

function validateAndPrepareFormData() {
  if ( ! validateForm() ) {
    return false;
  }

  let source_urls = getMultiSelectValues().split(',');
  let submission_data = {
    model_sheet: document.getElementById("model_sheet").value,
    url: source_urls,
    use_fake_api: document.getElementById("useFakeApi").checked,
    payload_data: {
      ticker: document.getElementById("ticker").value,
      source_urls: source_urls,
      line_items_column: document.getElementById("line_items_column").value,
      target_year: document.getElementById("target_year").value,
      target_quarter: document.getElementById("target_quarter").value,
      target_column: document.getElementById("target_column").value,
      comparison_year: document.getElementById("comparison_year").value,
      comparison_quarter: document.getElementById("comparison_quarter").value,
      comparison_column: document.getElementById("comparison_column").value,
      llm_model: document.getElementById("llm_model").value,
      hot_topics: document.getElementById("hot_topics").value,
      return_summary: document.getElementById("return_summary").checked,
      overwrite_target: document.getElementById("overwrite_target").checked
    }
  };

  return submission_data;
}

async function addUpdateStep() {
  // Validate form before adding as a step
  const submission_data = validateAndPrepareFormData();

  if (submission_data === false) {
    return false;
  }

  // Fetch our MultiSteps singleton
  let multiSteps = new MultiSteps();

  // Save the ticker and sec url to pre-populate in the next step
  multiSteps.setTicker(submission_data.payload_data.ticker);
  multiSteps.setSourceUrls(submission_data.payload_data.source_urls)

  // Add our step
  multiSteps.addStep(submission_data);

  // Load our steps view to display all steps
  await loadView("views/steps.html", "main");

  // Add all steps to the view
  populateAllSteps();
}

function deleteUpdateStep(stepIndex) {
  // Fetch our MultiSteps singleton
  let multiSteps = new MultiSteps();

  // Remove our step
  multiSteps.removeStep(parseInt(stepIndex));

  // Re-populate our view with all steps
  populateAllSteps();
}

function populateAllSteps() {
  // Fetch our MultiSteps singleton
  let multiSteps = new MultiSteps();
  // Fetch all steps
  const allSteps = multiSteps.getSteps();
  // Fetch our steps container to inject steps into
  const container = document.getElementById("steps");
  // Empty the container
  container.innerHTML = "";
  // Build the HTML for each step and append
  allSteps.forEach((value, index) => {
    const rowHTML = getStepRowHtml(index, value['model_sheet']);
    const rowElement = document.createElement('div');
    rowElement.innerHTML = rowHTML;
    container.appendChild(rowElement);
  });
  // Build and add our `Add Step` button to the container
  const addButton = document.createElement('button');
  addButton.classList = "text-blue-500 hover:text-blue-700 px-6 py-4 rounded-lg border-solid border-2 border-blue-500";
  addButton.id = "initializeAddStep";
  addButton.innerHTML = "+ Add Step";
  container.appendChild(addButton);

  let updateMultiStepModelButton = document.querySelector("#update-multi-step-model-button button");
  if (allSteps.length > 0) {
    updateMultiStepModelButton.classList.remove("hidden");
  } else {
    updateMultiStepModelButton.classList.add("hidden");
  }
}

function getStepRowHtml(index, model_sheet) {
  return `
    <div class="grid-row">
      <div class="text-blue-500">${index + 1}</div>
      <div class="step-description border-solid border-2 text-sm text-blue-500 border-blue-500 rounded-lg">Update "${model_sheet}"</div>
      <button class="delete-step-button rounded-lg text-blue-500" data-stepid="${index}">x</button>
      <div class="step-progress hidden" data-stepid="${index}">
        <svg class="pending w-11 h-11 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <circle cx="12" cy="12" r="9" stroke="currentColor" fill="none" />
          <line x1="8" y1="12" x2="16" y2="12" stroke="currentColor" stroke-linecap="round" />
        </svg>
        <div class="multi-step-spinner hidden w-7 h-7 bg-blue-500 rounded-full flex items-center justify-center">
          <span class="loading loading-spinner text-white"></span>
        </div>
        <svg class="success hidden w-11 h-11 text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path fill-rule="evenodd" d="M2.25 12a9.75 9.75 0 1119.5 0 9.75 9.75 0 01-19.5 0zm13.28-2.22a.75.75 0 10-1.06-1.06l-4.72 4.72-1.72-1.72a.75.75 0 10-1.06 1.06l2.25 2.25a.75.75 0 001.06 0l5.25-5.25z" clip-rule="evenodd" />
        </svg>
        <svg class="failure hidden w-11 h-11 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
  <div class="step-notification" id="notification-for-step-${index}"></div>`;
}

async function runAllUpdateSteps() {
  let multiSteps = new MultiSteps();  
  const allSteps = multiSteps.getSteps();

  let requestButtonMultiStep = document.getElementById("requestButtonMultiStep");
  requestButtonMultiStep.classList.add("hidden");

  let initializeAddStep = document.getElementById("initializeAddStep");
  initializeAddStep.classList.add("hidden");

  document.querySelectorAll('.step-progress').forEach(el => {
    el.classList.remove('hidden');
  });

  document.querySelectorAll(`.delete-step-button`).forEach(el => {
    el.classList.add('hidden');
  });

  let response;
  const startTime = performance.now();
  let notification_element = resetMessageUI("apiResponse");
  let all_step_success = true;
  for (const [index, step] of allSteps.entries()) {

    let step_progress_pending = document.querySelector(`.step-progress[data-stepid="${index}"] .pending`);
    step_progress_pending.classList.add("hidden");

    let step_progress_spinner = document.querySelector(`.step-progress[data-stepid="${index}"] .multi-step-spinner`);
    step_progress_spinner.classList.remove("hidden");

    let insert_summary_sheet = false;
    if (index === 0) {
      // We only want to create a single summary sheet
      insert_summary_sheet = true;
    }
    response = await submitUrl(step, insert_summary_sheet);

    step_progress_spinner.classList.add("hidden");

    let step_notification_element = resetMessageUI(`notification-for-step-${index}`)
    if (response.success) {
      displaySuccess(step_notification_element, response.message);
      let step_progress_success = document.querySelector(`.step-progress[data-stepid="${index}"] .success`);
      step_progress_success.classList.remove("hidden");
    } else {
      displayError(step_notification_element, response.message);
      let totalTime = Math.round((performance.now() - startTime) / 1000);
      const partial_completion_message = `Completed in ${totalTime}s.<br />Some steps failed to run`;
      displayError(notification_element, partial_completion_message);
      let step_progress_failure = document.querySelector(`.step-progress[data-stepid="${index}"] .failure`);
      step_progress_failure.classList.remove("hidden");
      all_step_success = false;
      break;
    }
  }
  if (all_step_success) {
    let totalTime = Math.round((performance.now() - startTime) / 1000);
    displaySuccess(notification_element, `Completed in ${totalTime}s`);
  }
}

async function prepareToSubmitUrl() {
  let submission_data = validateAndPrepareFormData();

  if (submission_data === false) {
    return false;
  }
 
  let button = document.getElementById("requestButton");

  button.innerHTML = '<span class="loading loading-spinner"></span>';
  // TODO we may need to try/catch this, but these innerHTML calls likely should work either way
  let response = await submitUrl(submission_data);
  button.innerHTML = "Update Model";

  let notification_element = resetMessageUI("apiResponse");
  if (response.success) {
    displaySuccess(notification_element, response.message);
  } else {
    displayError(notification_element, response.message);
  }
}

async function setSheetsDropdown() {
  await Excel.run(async (context) => {
    const sheetsSelect = document.getElementById("model_sheet");
    const sheets = context.workbook.worksheets;
    sheets.load("items/name,items/visibility");
    await context.sync();

    sheets.items.forEach((item) => {
      if (item.visibility === "Visible") sheetsSelect.add(new Option(item.name));
    });
  }).catch(error => {
    // Re-throw the error to be caught upstream
    throw error;
  });
}

async function changeYear(event) {
  document.getElementById("comparison_year").value = event.target.value - 1;
}

async function changeQuarter(event) {
  document.getElementById("comparison_quarter").value = event.target.value;
}

function validateForm() {

  let form = document.getElementById("main-form");
  let display_error_element = resetMessageUI("apiResponse");

  if ( ! form.checkValidity() ) {
    // Form is invalid. The :invalid pseudo class will be added to all invalid form fields
    form.querySelectorAll(":invalid").forEach(element => {
      // Add class that highlights the invalid field for the user
      toggleInvalidFormFieldClass(element);
      // Add a listener for toggling the class when the user updates the field
      addEventListenerToFormField(element);
    });
    // Display useful message to user about form invalidation
    displayError(display_error_element, 'Please add values to the highlighted fields');
    return false;
  }

  // Additional URL validation. Likely can be replaced with <input type='url'>
  const source_urls = getMultiSelectValues().split(',');
  for (const source_url of source_urls) {
    console.log(`Here is the sec url ${source_url}`)
    if ( ! isValidHttpUrl(source_url)) {
      displayError(display_error_element, `Invalid URL:<br/> ${source_url}`);
      return false;
    }
  }

  // Form is valid
  return true;
}

function toggleInvalidFormFieldClass(element) {
  let element_to_toggle = element;
  let css_class = 'form-validation-fail';
  // For the ticker, we target the parent <label> tag due to how this field is marked up
  if (element.id == 'ticker') {
    element_to_toggle = element.closest('label');
  }
  if (element.value.trim() === "") {
    element_to_toggle.classList.add(css_class);
  } else {
    element_to_toggle.classList.remove(css_class);
  }
}

function addEventListenerToFormField(element) {
  // We only add the event listener if it has not previously been added
  if (element.nodeName == 'INPUT' && element.oninput == null) {
    element.addEventListener('input', (event) => toggleInvalidFormFieldClass(event.target));
  }
  if (element.nodeName == 'SELECT' && element.onchange == null) {
    element.addEventListener('change', (event) => toggleInvalidFormFieldClass(event.target));
  }
}

/**
 * Submits a URL to our API backend for processing
 * @param {Object} submission_data - The submission data object
 * @param {string} submission_data.model_sheet - The Excel sheet we are using
 * @param {boolean} submission_data.use_fake_api - Whether or not we are using a mock response
 * @param {Object} submission_data.payload_data - Data to build our payload for our model update API
 * @param {string} submission_data.payload_data.ticker - Ticker value
 * @param {string} submission_data.payload_data.source_urls - The Source URLs we wish to process
 * @param {string} submission_data.payload_data.line_items_column - Line items column
 * @param {string} submission_data.payload_data.target_year - Target year
 * @param {string} submission_data.payload_data.target_quarter - Target quarter
 * @param {string} submission_data.payload_data.target_column - Target column
 * @param {string} submission_data.payload_data.comparison_year - Comparison year
 * @param {string} submission_data.payload_data.comparison_quarter - Comparison quarter
 * @param {string} submission_data.payload_data.comparison_column - Comparison column
 * @param {string} submission_data.payload_data.llm_model - LLM model to use for primary query
 * @param {string} submission_data.payload_data.hot_topics - a list of topics to include in the summary
 * @param {boolean} submission_data.payload_data.insert_summary_sheet - Whether to insert a summary sheet
 * @param {boolean} submission_data.payload_data.overwrite_target - If true, existing values in target period will be overwritten
 */
async function submitUrl(submission_data) {

  let response = {
    success: true,
    message: ''
  };

  await Excel.run(async (context) => {

    const sourceSheet = context.workbook.worksheets.getItem(submission_data.model_sheet);
    const payload = await buildPayload(context, sourceSheet, submission_data.payload_data);
    const startTime = performance.now();
    const result = await requestUpdateFromApi(submission_data.use_fake_api, context, payload);

    const errorMessage = result.error || result.data.error_message;
    if (errorMessage) {
      response.success = false;
      response.message = errorMessage;
    } else {
      const timestamp = generateTimestamp();
      if (submission_data.payload_data.return_summary) {
        await insertSummarySheet(
          context,
          result.data,
          timestamp,
          submission_data.payload_data.ticker,
          submission_data.payload_data.target_year,
          submission_data.payload_data.target_quarter,
          submission_data.payload_data.source_urls
        );
      }
      if (responseContainsActualPeriodData(result.data)) {
        await insertUpdateLog(
          context,
          result.data,
          timestamp,
          sourceSheet,
          submission_data.payload_data.line_items_column,
          submission_data.payload_data.comparison_column,
          submission_data.payload_data.target_column,
          submission_data.payload_data.overwrite_target
        );
        response.message = "Update Completed";
      } else {
        response.success = false;
        response.message = "Response received without actuals data";
      }
    }

    const endTime = performance.now();
    response.message += `<br/>Time taken: ${Math.round((endTime - startTime) / 1000)}s`;
    window.scrollTo(0, document.body.scrollHeight);

    // Sync the context to run the previous API call
    await context.sync();

  }).catch(error => {
    // Re-throw error to be caught upstream
    console.log("error caught", error);
    // Update the response in case of error
    response = {
      success: false,
      message: `Error occurred: ${error.message}`
    };
  });

  // Return the response object
  return response;
}

function responseContainsActualPeriodData(data) {
  return (
    data.actual_period &&
    Array.isArray(data.actual_period) &&
    data.actual_period.length > 0 &&
    Array.isArray(data.actual_period[0]) &&
    data.actual_period[0].length > 0
  );
}

/**
 * Build the payload for our model update API
 * @param {Excel.RequestContext} context - The EXcel Request Context
 * @param {Excel.Worksheet} - The Excel Worksheet
 * @param {Object} payload_data - The submission data object
 * @param {string} payload_data.ticker - Ticker value
 * @param {string} payload_data.source_urls - list of URLs to process
 * @param {string} payload_data.line_items_column - Line items column
 * @param {string} payload_data.target_year - Target year
 * @param {string} payload_data.target_quarter - Target quarter
 * @param {string} payload_data.target_column - Target column
 * @param {string} payload_data.comparison_year - Comparison year
 * @param {string} payload_data.comparison_quarter - Comparison quarter
 * @param {string} payload_data.comparison_column - Comparison column
 */
async function buildPayload(context, worksheet, payload_data) {

  const workbook = context.workbook;
  workbook.load("name");
  worksheet.load("name");
  await context.sync();

  const payload = {
    ticker: payload_data.ticker,
    excel_file_name: workbook.name,
    source_urls: payload_data.source_urls,
    llm_model: payload_data.llm_model,
    hot_topics: payload_data.hot_topics,
    return_summary: payload_data.return_summary,
    overwrite_target: payload_data.overwrite_target,
    line_items: {
      excel_sheet: worksheet.name,
      column: payload_data.line_items_column,
      rows: [],
    },
    target_period: {
      excel_sheet: worksheet.name,
      year: Number(payload_data.target_year),
      column: payload_data.target_column,
      rows: [],
    },
    comparison_period: {
      excel_sheet: worksheet.name,
      year: Number(payload_data.comparison_year),
      column: payload_data.comparison_column,
      rows: [],
    },
  };

  const target_quarter_int = Number(payload_data.target_quarter);
  const comparison_quarter_int = Number(payload_data.comparison_quarter);

  if (target_quarter_int >= 1 && target_quarter_int <= 4) payload.target_period.quarter = target_quarter_int;
  if (target_quarter_int >= 5) payload.target_period.half = target_quarter_int - 4; // h1 = 5, h2 = 6
  if (comparison_quarter_int >= 1 && comparison_quarter_int <= 4) payload.comparison_period.quarter = comparison_quarter_int;
  if (comparison_quarter_int >= 5) payload.comparison_period.half = comparison_quarter_int - 4; // h1 = 5, h2 = 6

  // unmerge any merged columns before pulling them into the request
  let entire_sheet = worksheet.getRange();
  entire_sheet.unmerge();
  await context.sync();

  // if "overwrite target" mode is false, skip rows with values in the target period
  if (payload_data.overwrite_target) {
    var skip_list = [];
  } else {
    var skip_list = await getSkipList(context, worksheet, payload);
  }

  await getColumnValues(context, worksheet, payload.line_items, skip_list);
  await getColumnValues(context, worksheet, payload.comparison_period, skip_list);
  return payload;
}

async function getColumnValues(context, sheet, obj, skip_list) {
  const range = sheet.getRange(`${obj.column}:${obj.column}`).getUsedRange();
  range.load({ select: "text,formulas,rowIndex" });
  await context.sync();

  // Excel gives us an array of arrays for the text and formulas of the range
  // Outer array is rows, inner array is columns
  range.text.forEach((row, index) => {
    const text = row[0];
    const formulaString = range.formulas[index][0].toString(); // could be a number type
    const formulaExEquals = formulaString.substring(1);
    const rowNumber = index + range.rowIndex + 1

    // for formulas that are just a number, strip the formula and send the number:
    if (skip_list.includes(rowNumber)) { // do nothing
    } else if (text && formulaString[0] === "=" && !isNaN(formulaString.substring(1))) {
      obj.rows.push({ row: rowNumber, text: formulaExEquals });
    // if there's a formula, send the formula:
    } else if (text && formulaString[0] === "=") {
      obj.rows.push({ row: rowNumber, text, formula: formulaString });
    // otherwise, send the string:
    } else if (text) {
      obj.rows.push({ row: rowNumber, text });
    }
  });
}

async function getSkipList(context, sheet, payload) {
  const target_col = sheet.getRange(`${payload.target_period.column}:${payload.target_period.column}`).getUsedRange();
  target_col.load({ select: "text,rowIndex" });
  await context.sync();

  // skip rows that already have target period values
  var skip_list = [];
  target_col.text.forEach((row, index) => {
    if (row[0] == '' || row[0] == 'No Value') {
    } else {
      skip_list.push(index + target_col.rowIndex + 1);
    }
  });

  return skip_list;
}


function isValidHttpUrl(value) {
  try {
    const newUrl = new URL(value);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch (err) {
    return false;
  }
}

function resetMessageUI(elementId) {
  const element = document.getElementById(elementId);
  element.innerHTML = "";
  element.classList.remove("text-success", "text-error", "text-warning");
  return element;
}

function displaySuccess(element, message) {
  element.classList.add("text-success");
  element.innerHTML = message;
}

function displayError(element, message) {
  element.classList.add("text-error");
  element.innerHTML = message;
}

function displayWarning(element, message) {
  element.classList.add("text-warning");
  element.innerHTML = message;
}

async function requestUpdateFromApi(useFakeApi, context, payload) {
  if (useFakeApi) {
    console.log("Loading sample response");
    const response = await fetch(`./amzn_response_2024q1.json`);
    // const response = await fetch(`./response_error_message.json`);
    if (!response.ok) return { error: "Failed to load sample response" };
    const data = await response.json();
    await delay(1000);
    return { data };
  } else {
    console.log("Using real API");
    try {
      let response = await updateModel(payload);
      if (response?.error) {
        await saveMessageToSentry(payload, response, SentryLoggingLevels.error);
      } else {
        await saveMessageToSentry(payload, response, SentryLoggingLevels.info);
      }
      console.log("Response from middle tier", response);
      return response;
    } catch (error) {
      console.log(error);
      let errorResponse = { error: `Request failed: ${error.message}` };
      await saveMessageToSentry(payload, errorResponse, SentryLoggingLevels.error);
      return errorResponse;
    }
  }
}

async function saveMessageToSentry(payload, response, level) {
  let clerkId = Clerk.user.id;
  
  Sentry.captureMessage('User Activity', {
    level: level, // Level can be 'debug', 'info', 'warning', 'error', 'fatal'
    extra: {
      id: clerkId,
      request: JSON.stringify(payload),
      response: JSON.stringify(response),
    },
  });
}

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

function generateTimestamp() {
  return new Date()
    .toISOString()
    .replaceAll(/[:TZ\.\-]/g, "")
    .substring(2, 14);
}

function* createCounter(start = 0, step = 1) {
  let num = start;
  while (true) {
      yield num;
      num += step;
  }
}

async function insertSummarySheet(
  context,
  data,
  timestamp,
  ticker,
  year,
  quarter,
  source_urls
) {
  const sheet = context.workbook.worksheets.add(`Summary - ${timestamp}`);
  if (data.summary && Array.isArray(data.summary) && data.summary.length && Array.isArray(data.summary[0])) {

    // We start on row 2
    const rowCounterColumbB = createCounter(2, 1);
    
    const margin_column = sheet.getRange(`A:A`)
    margin_column.format.columnWidth = 20

    const title_cell = sheet.getRange(`B${rowCounterColumbB.next().value}`)
    title_cell.values = `Update Summary`
    title_cell.format.font.size = 18;
    title_cell.format.font.bold = true;

    quarter ??= `FY`
    if (quarter === "5") {
      quarter = "1H";
    } else if (quarter === "6") {
      quarter = "2H";
    } else if (quarter === "0") {
      quarter = "FY";
    }
    const subtitle_cell = sheet.getRange(`B${rowCounterColumbB.next().value}`)
    subtitle_cell.values = `${ticker} ${year}-${quarter}`

    rowCounterColumbB.next(); // spacing
    for (const source_url of source_urls) {
      const url_cell = sheet.getRange(`B${rowCounterColumbB.next().value}`)
      url_cell.values = `=hyperlink("${source_url}")`
      url_cell.format.font.underline = `single`;
      url_cell.format.font.color = `#0000FF`;
    }
    
    const content_row_start = rowCounterColumbB.next().value;
    for (const item of data.summary[0]) {
      if (item.row && item.text) {
        const cell = sheet.getRange(`B${content_row_start + item.row}`);
        cell.values = [[item.text]];
      }
    }
  } else {
    sheet.getRange("B1").values = [["No summary data provided"]];
  }
  //sheet.activate();
  await context.sync();
} 

async function insertUpdateLog(
  context,
  data,
  timestamp,
  sourceSheet,
  line_items_column,
  comparison_column,
  target_column,
  overwrite_target
) {

  // write line items, comparison, and estimate columns
  const sheetName = `Update Log - ${timestamp}`;
  const sheet = context.workbook.worksheets.add(sheetName.substring(0, 30)); // Max sheet name 31 chars
  sheet.freezePanes.freezeRows(1);

  const sourceLineItems = sourceSheet.getRange(`${line_items_column}:${line_items_column}`);
  sheet.getRange("A:A").copyFrom(sourceLineItems, Excel.RangeCopyType.formats);
  sheet.getRange("A:A").copyFrom(sourceLineItems, Excel.RangeCopyType.values);

  const sourceComparisonColumn = sourceSheet.getRange(`${comparison_column}:${comparison_column}`);
  sheet.getRange("B:B").copyFrom(sourceComparisonColumn, Excel.RangeCopyType.formats);
  sheet.getRange("B:B").copyFrom(sourceComparisonColumn, Excel.RangeCopyType.values);

  const sourceTargetColumn = sourceSheet.getRange(`${target_column}:${target_column}`);
  sheet.getRange("C:C").copyFrom(sourceTargetColumn, Excel.RangeCopyType.formats);
  sheet.getRange("C:C").copyFrom(sourceTargetColumn, Excel.RangeCopyType.values);
  await context.sync(); 

  // update original model
  // copy comparison column formats & formulas to actual column
  sourceTargetColumn.copyFrom(sourceComparisonColumn, Excel.RangeCopyType.formats);
  const formulaRanges = sourceComparisonColumn.getSpecialCellsOrNullObject(Excel.SpecialCellType.formulas);
  const offsetRange = sourceSheet.getRange(`${comparison_column}:${target_column}`);
  offsetRange.load('columnCount');
  await context.sync();
  const actualOffset = offsetRange.columnCount-1;
  if (formulaRanges.isNullObject) {
        console.log("No comparison cells have formulas");
      } else {
        formulaRanges.load('areaCount','areas');
        await context.sync();
        const formulaRangeAreas = formulaRanges.areas;
        formulaRangeAreas.load('items','rowIndex','rowCount');
        await context.sync();
        for (const formulaRange of formulaRangeAreas.items) {
          const actualFormulaRange = formulaRange.getOffsetRange(0,actualOffset);
          actualFormulaRange.copyFrom(formulaRange);
          actualFormulaRange.format.fill.color = "LightBlue"; //#ADD8E6
          
          // write "formula" to source doc column in update log
          const firstRow = formulaRange.rowIndex + 1
          const lastRow = formulaRange.rowIndex + formulaRange.rowCount
          sheet.getRange(`F${firstRow}:F${lastRow}`).values = 'Formula';
        }
      }

  // delete estimated numbers
  const targetNumberRanges = sourceTargetColumn.getSpecialCellsOrNullObject(
    Excel.SpecialCellType.constants, Excel.SpecialCellValueType.numbers);
  await context.sync();

  if (overwrite_target === false) {
    console.log("Overwrite target is false, not clearing target period");
  } else if (targetNumberRanges.isNullObject) {
    console.log("No values to clear");
  } else {
    targetNumberRanges.clear(Excel.ClearApplyTo.contents);
  }

  // overwrite actuals in actual column
  for (const item of data.actual_period[0]) {
    if (item.row && item.text) {
      const cell = sourceSheet.getRange(`${target_column}${item.row}`);
      cell.values = [[item.text]];
      cell.format.fill.color = "SpringGreen";
    }
  }
  // highlight errors
  for (const item of data.actual_period[2]) {
    if (item.row && item.text === "Error") {
      const cell = sourceSheet.getRange(`${target_column}${item.row}`);
      cell.format.fill.color = "Tomato";
      cell.values = [['No Value']];
      cell.format.font.color = "White";
    }
  }

  // continue writing update log
  sheet.getRange("D:D").copyFrom(sourceTargetColumn, Excel.RangeCopyType.formats);
  sheet.getRange("D:D").copyFrom(sourceTargetColumn, Excel.RangeCopyType.values);
  sheet.getRange("E:E").copyFrom(sourceComparisonColumn, Excel.RangeCopyType.formats);

  // location
  for (const item of data.actual_period[1]) {
    if (item.row && item.text) {
      const location_cell = sheet.getRange(`G${item.row}`);
      location_cell.values = [[item.text]];
    }
  }

  // source doc
  for (const item of data.actual_period[4]) {
    if (item.row && item.text && item.href) {
      const source_doc_cell = sheet.getRange(`F${item.row}`);
      source_doc_cell.values = `=hyperlink("${[[item.href]]}","${[[item.text]]}")`;
      source_doc_cell.format.font.underline = `single`;
      source_doc_cell.format.font.color = `#0000FF`;
    }
  } 

  // Anomaly
  for (const item of data.actual_period[3]) {
    if (item.row && item.text) {
      const cell = sheet.getRange(`I${item.row}`);
      cell.values = [[item.text]];
    }
  }

  // Anomaly level
  for (const item of data.actual_period[2]) {
    if (item.row && item.text && Number(item.row) > 1) {
      const level_cell = sheet.getRange(`H${item.row}`);
      level_cell.values = [[item.text]];
      const row = level_cell.getEntireRow();
      
      if (item.text === "Error") {
          row.format.fill.color = "Tomato";
          row.format.font.color = "White";
        } else if (item.text === "Warning") {
          row.format.font.color = "DarkOrange";
        }
      }
    }

  const detail_range = sheet.getRange(`F:I`);
  detail_range.format.columnWidth = 100;

  // update log headers 
  sheet.getRange("A1").values = [[""]];
  sheet.getRange("B1").values = [["Comp"]];
  sheet.getRange("C1").values = [["Estimate"]];
  sheet.getRange("D1").values = [["Actual"]];
  sheet.getRange("E1").values = [["Surprise"]];
  sheet.getRange("F1").values = [["Source Doc"]];
  sheet.getRange("G1").values = [["Location"]];
  sheet.getRange("H1").values = [["Anomaly Level"]];
  sheet.getRange("I1").values = [["Anomaly"]];
  sheet.getRange("A1:I1").copyFrom(sourceSheet.getRange("A1"), Excel.RangeCopyType.formats);

  // write surprise
  await context.sync();
  const actualColumn = sheet.getRange("D:D").getUsedRange();
  actualColumn.load("values,text");
  await context.sync();

  actualColumn.values.forEach((row, index) => {
    const value = row[0];
    if (typeof value === "number") {
      const text = actualColumn.text[index][0];
      const surpriseCell = sheet.getRange(`E${index + 1}`);
      if (!text.includes("%")) {
        surpriseCell.formulas = [[`=(D${index + 1}-C${index + 1})/C${index + 1}`]];
        surpriseCell.numberFormat = [["0.0%"]];
      } else if (text.includes("%")) {
        surpriseCell.formulas = [[`=TEXT(ROUND((D${index + 1}-C${index + 1})*10000,0),0)&" bps"`]];
        surpriseCell.format.horizontalAlignment = "Right";
      }
    }
  });

  await context.sync();
}

async function fetchSecUrlsForTicker(event) {

  // Verify that our value hasn't changed
  const ticker_input = event.target;
  const ticker = ticker_input.value;
  const previousTicker = ticker_input.previousValue || "";

  if (ticker === previousTicker) {
    // Our ticker value has not changed, do nothing
    return false;
  }

  // Store our selection for next click
  ticker_input.previousTicker = ticker;

  // Disable the button while fetching
  disableButton();

  // Clear our SEC url
  clearMultiSelect();

  // Hide arrow, show spinner
  toggleSpinner();
  
  await Excel.run(async (context) => {

    let response = await fetchSecUrlsForTickerFromApi(ticker);

    // Hide spinner, show arror, regardless of whether we received URLs
    toggleSpinner();

    if ('error' in response) {
      // API call returned an error
      console.log(response);
      return false;
    }

    if (response["sec_urls"].length === 0) {
      console.log("No SEC URLs were found");
      return false;
    }
    
    // Re-enable our button
    enableButton();

    // Get returned SEC URLs
    const sec_urls = response["sec_urls"];
    
    // Add all URLs to our dropdown
    const multi_select = new MultiSelect();
    sec_urls.forEach((sec_url, index) => {
      let release = createReleaseTitle(sec_url)
      multi_select.getElement().addOption({
        id: index,
        title: release,
        url: sec_url["url"]
      })
    });

    // Cache for later use
    let multiSteps = new MultiSteps();
    multiSteps.setSecUrlOptions(sec_urls);
    
  });
}

/**
 * Create a selectable title from URL metadata
 * @param {Object} sec_url
 * @param {string} sec_url.fiscal_year
 * @param {string} sec_url.fiscal_quarter
 * @param {string} sec_url.fiscal_year
 * @param {string} sec_url.type - 10-K, 8-K, etc
 */
function createReleaseTitle(sec_url) {
  let release = `${sec_url["fiscal_year"]} Q${sec_url["fiscal_quarter"]} ${sec_url["type"]}`;
  if (sec_url["type"] === "10-K") {
    release = `${sec_url["fiscal_year"]} FY ${sec_url["type"]}`;
  }
  return release;
}

function clearMultiSelect() {
  const multi_select = new MultiSelect()
  multi_select.getElement().setValue('');
  multi_select.getElement().clearOptions();
}

function toggleSpinner() {
  document.getElementById("sec-url-dropdown-arrow").classList.toggle('hidden');
  document.getElementById("sec-url-fetch-spinner").classList.toggle('hidden');
}

function enableButton() {
  let button = document.getElementById("sec-url-dropdown-button");
  button.classList.remove("bg-gray-500");
  button.classList.add("bg-blue-500", "hover:bg-blue-700");
  button.disabled = false;
}

function disableButton() {
  let button = document.getElementById("sec-url-dropdown-button");
  button.classList.add("bg-gray-500");
  button.classList.remove("bg-blue-500", "hover:bg-blue-700");
  button.disabled = true;
}
